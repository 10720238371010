$(document).ready(function () {
    // make the acordion between date filter strategies work
    $("#dateButtons > .btn").click(function () {
        $(this).addClass("active").siblings().removeClass("active");
        $("#dateCollapses .collapse").collapse("hide");
        $("#dateFilterMode").val($(this).attr("value"));
    });

    // JS the date pickers
    flatpickr(".flatpickr", {
        wrap: true,
        allowInput: true,
        dateFormat: "m-d-Y",
    });

    if ($(".multiselect").length > 0) {
        $(".multiselect").select2();
    }
});

window.UpdateBaseOptions = function () {
    var organizationSelect = $("#organization");
    var baseSelect = $("#base");

    organizationSelect.prop("disabled", true);
    baseSelect.prop("disabled", true);

    var selectedOrganizationId = $("#organization").val();

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "GetBaseOptions.php");
    xhr.setRequestHeader("X-CSRF-Token", "<?=csrf_token()?>");
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.onload = function () {
        var baseOptions = JSON.parse(xhr.response).sort((a, b) => {
            if (a.Name < b.Name) {
                return -1;
            }
            if (a.Name > b.Name) {
                return 1;
            }

            return 0; // names must be equal
        });

        baseSelect.empty(); // remove old options
        baseSelect.append($("<option></option>").attr("value", 0).text("All"));

        $.each(baseOptions, function (index, option) {
            baseSelect.append(
                $("<option></option>")
                    .attr("value", option.BaseID)
                    .text(option.Name)
            );
        });

        organizationSelect.prop("disabled", false);
        baseSelect.prop("disabled", false);
    };

    xhr.send(JSON.stringify({ organization: selectedOrganizationId }));

    if ($("#surveysFilter").length > 0) {
        UpdateSurveysOptions();
    }
};

window.UpdateReportBaseOptions = function () {
    var organizationSelect = $("#organization");
    var baseSelect = $("#base");

    organizationSelect.prop("disabled", true);
    baseSelect.prop("disabled", true);

    var selectedOrganizationId = $("#organization").val();

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "GetReportBaseOptions.php");
    xhr.setRequestHeader("X-CSRF-Token", "<?=csrf_token()?>");
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.onload = function () {
        var baseOptions = JSON.parse(xhr.response).sort((a, b) => {
            if (a.Name < b.Name) {
                return -1;
            }
            if (a.Name > b.Name) {
                return 1;
            }

            return 0; // names must be equal
        });

        baseSelect.empty(); // remove old options
        baseSelect.append($("<option></option>").attr("value", 0).text("All"));

        $.each(baseOptions, function (index, option) {
            baseSelect.append(
                $("<option></option>")
                    .attr("value", option.BaseID)
                    .text(option.Name)
            );
        });

        organizationSelect.prop("disabled", false);
        baseSelect.prop("disabled", false);
    };

    xhr.send(JSON.stringify({ organization: selectedOrganizationId }));

    if ($("#surveysFilter").length > 0) {
        UpdateSurveysOptions();
    }
};

window.UpdateSurveysOptions = function (includeInactiveSurveys = false) {
    var surveysSelect = $("#surveys");

    surveysSelect.prop("disabled", true);

    var selectedOrganizationId = $("#organization").val();

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "GetSurveysOptions.php");
    xhr.setRequestHeader("X-CSRF-Token", "<?=csrf_token()?>");
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.onload = function () {
        var surveyOptions = JSON.parse(xhr.response).sort((a, b) => {
            if (a.Name < b.Name) {
                return -1;
            }
            if (a.Name > b.Name) {
                return 1;
            }

            return 0; // names must be equal
        });

        surveysSelect.empty(); // remove old options

        $.each(surveyOptions, function (index, option) {
            if (window.hasOwnProperty("surveyOptionFilter")) {
                if (!window.surveyOptionFilter(option)) {
                    return; // this is the equivalent of a continue in the .each()
                }
            }

            surveysSelect.append(
                $("<option></option>")
                    .attr("value", option.SurveyID)
                    .attr("selected", true)
                    .text(option.Name)
            );
        });

        surveysSelect.prop("disabled", false);
    };

    xhr.send(
        JSON.stringify({
            organization: selectedOrganizationId,
            includeInactiveSurveys: includeInactiveSurveys,
        })
    );
};
