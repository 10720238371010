window.onerror = function (msg, url, line, col, error) {
    console.log(`message: ${msg}`);
    console.log(`source: ${url}`);
    console.log(`lineno: ${line}`);
    console.log(`colno: ${col}`);
    console.log(`error: ${error}`);

    // Note that col & error are new to the HTML 5 spec and may not be
    // supported in every browser.  It worked for me in Chrome.
    var extra = !col ? "" : "\ncolumn: " + col;
    extra += !error ? "" : "\nerror: " + error;

    // You can view the information in an alert to see things working like this:
    // alert("Error: " + msg + "\nurl: " + url + "\nline: " + line + extra);

    // Report this error via ajax so you can keep track of what pages have JS issues
    var error_data = {
        message: msg,
        url: document.location.href,
        lineno: line,
        colno: col,
    };

    if (error != null) {
        error_data["error.name"] = error.name; // e.g. ReferenceError
        error_data["error.message"] = error.line;
        error_data["error.stack"] = error.stack;
    }

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/LogUiError.php");
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify(error_data));

    var suppressErrorAlert = true;
    // If you return true, then error alerts (like in older versions of
    // Internet Explorer) will be suppressed.
    return suppressErrorAlert;
};
