// from https://stackoverflow.com/a/75768155/21919663

export const jitterEffectsPlugin = {
    id: "jitterEffects",
    beforeDatasetDraw: function (ctx, args, options) {
        if (
            ctx.config.type != "line" ||
            ctx.options.plugins.jitter == undefined
        ) {
            return;
        }
        var _args = args,
            dataIndex = _args.index,
            meta = _args.meta;
        var points = meta.data.map(function (el) {
            return {
                x: el.x,
                y: el.y,
            };
        });
        var dsLength = ctx.data.datasets.length;
        var adjustedMap = []; // keeps track of adjustments to prevent double offsets

        for (var datasetIndex = 0; datasetIndex < dsLength; datasetIndex += 1) {
            if (dataIndex !== datasetIndex) {
                var datasetMeta = ctx.getDatasetMeta(datasetIndex);
                datasetMeta.data.forEach(function (el) {
                    var overlapFilter = points.filter(function (point) {
                        return point.x === el.x && point.y === el.y;
                    });

                    var overlap = false;
                    var overObj = JSON.parse(JSON.stringify(overlapFilter));
                    for (var i = 0; i < overObj.length; i++) {
                        if (
                            overObj[i]["x"] === el.x &&
                            overObj[i]["y"] === el.y
                        ) {
                            overlap = true;
                            break;
                        }
                    }
                    if (overlap) {
                        var adjusted = false;
                        var adjustedFilter = adjustedMap.filter(function (
                            item
                        ) {
                            return (
                                item.datasetIndex === datasetIndex &&
                                item.dataIndex === dataIndex
                            );
                        });
                        var adjObj = JSON.parse(JSON.stringify(adjustedFilter));
                        for (var i = 0; i < adjObj.length; i++) {
                            if (
                                adjObj[i]["datasetIndex"] === datasetIndex &&
                                adjObj[i]["dataIndex"] === dataIndex
                            ) {
                                adjusted = true;
                                break;
                            }
                        }

                        if (!adjusted && datasetIndex % 2) {
                            el.x += 4;
                        } else {
                            el.x -= 4;
                        }

                        adjustedMap.push({
                            datasetIndex: datasetIndex,
                            dataIndex: dataIndex,
                        });
                    }
                });
            }
        }
    },
};
