require("./bootstrap");
require("@fortawesome/fontawesome-free");
require("bootstrap");

// fileinput (upload files)
require("bootstrap-fileinput/js/plugins/piexif");
require("bootstrap-fileinput/js/plugins/sortable");
fileinput = require("bootstrap-fileinput");
require("bootstrap-fileinput/themes/fas/theme.js");

// datatables
DataTable = require("datatables.net");
require("datatables.net-bs5");

flatpickr = require("flatpickr/dist/flatpickr.min");
jQuery = $ = require("jquery");
require("jquery-ui-dist/jquery-ui.min.js");
require("select2/dist/js/select2.min");
require("popper.js");

// tinymce
tinyMCE = require("tinymce");
require("tinymce/themes/silver/theme");
require("tinymce/icons/default");
require("tinymce/plugins/table");
require("tinymce/plugins/code");
require("tinymce/plugins/lists");
require("tinymce/plugins/paste");
require("tinymce/plugins/fullscreen");
require("video.js");

// emails-input
require("emails-input/dist/main.js");

// password strength check
zxcvbn = require("zxcvbn/dist/zxcvbn.js");

// survey js - https://surveyjs.io/Documentation/Library?id=get-started-jquery
Survey = require("survey-jquery/survey.jquery.min.js");

SurveyJsWidgets = require("surveyjs-widgets");
SurveyJsWidgets.jqueryuidatepicker(Survey);

Survey.StylesManager.applyTheme("defaultV2");

// survey js - https://surveyjs.io/pdf-generator/documentation/get-started-jquery
SurveyPDF = require("survey-pdf/survey.pdf.min.js");

Showdown = require("showdown/dist/showdown.min.js");

ChartJS = require("chart.js");
Chart = ChartJS.Chart;
Chart.register(...ChartJS.registerables);

// https://github.com/kurkle/chartjs-plugin-autocolors
AutoColors = require("chartjs-plugin-autocolors");
Chart.register(AutoColors);

// https://github.com/DavideViolante/chartjs-plugin-labels
Labels = require("chart.js-plugin-labels-dv");
Chart.register(Labels.getChartLabelPlugin());

Jitter = require("./jitter-chart-plugin");
Chart.register(Jitter.jitterEffectsPlugin);

require("./error-listening");
require("./report-filter");
